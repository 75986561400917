import React from "react";
// import PropTypes from "prop-types";
import { toast } from "react-toastify";
// import { InfoCircleFilled, ExclamationCircleFilled, WarningFilled, CheckCircleFilled } from '@ant-design/icons'


// export const displayIcon = (type) => {
//     switch (type) {
//         case "success":
//             return <CheckCircleFilled />
//         case "info":
//             return <InfoCircleFilled />
//         case "error":
//             return <ExclamationCircleFilled />
//         case "warning":
//             return <WarningFilled />
//         default:
//             return <ExclamationCircleFilled />;
//     }
// };

// const ToastMessage = ({ type, msg }) =>
//     toast[type](
//         <div style={{ display: "flex" }}>
//             <div style={{ flexGrow: 1, fontSize: 15, padding: "8px 12px" }}>
//                 {msg}
//             </div>
//         </div>
//     );

// ToastMessage.propTypes = {
//     msg: PropTypes.string.isRequired,
//     type: PropTypes.string.isRequired
// };

// ToastMessage.dismiss = toast.dismiss;




export const toastM = {
    // taost: {
    default: (msg, opt = {}) => {
        toast.info(msg)
    },
    error: (msg, opt) => {
        toast.error(msg)
    },
    info: (msg, opt) => {
        // ToastMessage({ type: 'info', msg: msg })
        toast.info(msg)
    },
    warning: (msg, opt) => {
        // ToastMessage({ type: 'warning', msg: msg })
        toast.warn(msg)
    },
    success: (msg, opt) => {
        // ToastMessage({ type: 'success', msg: msg })
        toast.success(msg)
    }
    // }
}



export const TOAST = (function () {
    // Notification.config({
    //   placement: 'topRight',
    //   bottom: 50,
    //   duration: 4
    // })

    const mergeOptions = (msg, opt) => {
        return Object.assign({},
            {
                message: msg
            },
            opt
        )
    }
    return {
        default: (msg, opt = {}) => {
            Notification.open(mergeOptions(msg, opt))
        },
        error: (msg, opt) => {
            Notification.error(mergeOptions(msg, opt))
        },
        info: (msg, opt) => {
            Notification.info(mergeOptions(msg, opt))
        },
        warning: (msg, opt) => {
            Notification.warning(mergeOptions(msg, opt))
        },
        success: (msg, opt) => {
            Notification.success(mergeOptions(msg, opt))
        }
    }
})()


const Dev = (props) => {
    return <></>
};

export default Dev