
import { NextApiRequest, NextApiResponse } from "next";
import { Config } from "../../../config/Constants";
import axios, { AxiosError, AxiosResponse } from "axios";


export const Axios = axios.create({
    baseURL: Config.url.API_URL,
    headers: {
        'device-type': '30'
    }
    // xsrfCookieName: "csrftoken",
    // xsrfHeaderName: "X-CSRFToken",
    // crossdomain: true,
    // headers: {
    //     //   'X-User-Agent': `*`,
    //     //   'Content-Type':`multipart/form-data`
    //     "X-User-Agent": `nevendo-web`,
    //     //   'Access-Control-Allow-Headers':'origin, content-type, accept'
    // },
});



export default async function handler(req: NextApiRequest, res: NextApiResponse) {

    return await login(req)
        .then(x => {
            const data = x.data
            const exp = ` expires=${(new Date(Date.now() + 86400 * 1000)).toUTCString()}; path=/;`;

            res.setHeader('Set-Cookie', [
                `nevendo_user_id=${data.nevendo_user_id};${exp}`,
                `token=${data.token};${exp}`,
                `nevendo_user_name=${data.nevendo_user_name};${exp}`,
                `nevendo_user_email=${data.nevendo_user_email};${exp}`,
                `nevendo_events=${data.nevendo_events};${exp}`,
                `eventId=${data.nevendo_events[0]};${exp}`,
            ]);
            return res.status(200).json({})
        })
        .catch(x => {
            if (x.response) {
                return res.status(x.response.status).send(x.response.data)// .status(401).send(x.response)
            } else {
                return res.status(500).send({ message: 'Server Error ', code: x.code })// .status(401).send(x.response)
            }
        })
    // const data = await response.data




    //  res.writeHead(302, {
    //     Location: '/support',
    //     'Set-Cookie': [
    //         `token=${data.token}; path=/;`,
    //         `nevendo_user_id=${data.nevendo_user_id}; path=/;`,
    //         `nevendo_user_name=${data.nevendo_user_name}; path=/;`,
    //         `nevendo_user_email=${data.nevendo_user_email}; path=/;`,
    //         `nevendo_events=${data.nevendo_events}; path=/;`
    //     ]
    // })
    // .end()

    // return res.status(200).json({})
    // return NextResponse.redirect('/delegates')

    // return res//.redirect(302, '/delegates')
    // return {
    //     res: res,
    //     redirect: {
    //         permanent: false,
    //         destination: "/delegates"
    //     }
    // }
    // return res//.status(200)
    // } catch (error) {
    //     const errors = error as Error | AxiosError;
    //     if (axios.isAxiosError(error)) {
    //         return res.status(error.response?.status || 500).json(error.response.data)
    //     }
    //     return res.status(501).json({ 'message': "Error" })

    // }
}


export async function login(req: NextApiRequest) {
    return await Axios.post("/login", req.body).then(e => e.data)
}
export async function getEvents() {
    return await Axios.get(Config.url.API_BASE_URL + "/events").then(e => e.data)
}

export async function getEventDetails(eventId: number | string) {
    return await Axios.get(eventId + "/eventdetails").then(e => e.data)
}
export async function getExhibitorLoginScreen(eventId: number | string, params: any) {
    return await Axios.get(eventId + "/banner", { params: params || {} }).then(e => e.data)
}


export async function loginDelegate(eventId: number, username: String) {
    return await Axios.post(`/${eventId}/login`, { 'username': username }).then(e => e.data)
}
export async function loginDelegateOtp(eventId: number, username: String, otpKey: String, otp: String) {
    return await Axios.post(`/${eventId}/login`, { 'username': username, 'key': otpKey, 'otp': otp, 'device_type': 30, }).then(e => e.data)
}

export async function sendOtp(eventId: number, otpKey: String, attempt: number) {
    return await Axios.post(`/${eventId}/send_otp`, { 'key': otpKey, 'device_type': 30, 'attempt': attempt }).then(e => e.data)
}
